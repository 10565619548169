import css from "./css/blog.module.css"
import { useEffect } from "react"
import ApplySteps from "../section/home/ApplySteps"
import FeatureGrid_Blog from "../compoients/FeatureGrid_Blog"

const Blog = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  return (
    <div className={`${css.container} marginFixTop`}>
      <div className={css.bg_container}>
        <div className={css.title}>
          <h1>熱門文章</h1>
        </div>
      </div>
      <div className={css.form_container}>
        <FeatureGrid_Blog />
      </div>
    </div>
  )
}

export default Blog
