import React, { createContext, useReducer } from "react";

// Initial state for the authentication context
const initialState = {
  loggedIn: false,
  dashboardURL: "",
};

// Reducer function for handling authentication actions
const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        loggedIn: true,
        dashboardURL: action.payload.dashboardURL,
      };
    case "LOGOUT":
      return {
        ...state,
        loggedIn: false,
        dashboardURL: "",
      };
    default:
      return state;
  }
};

// Create the authentication context
export const AuthContext = createContext();

// AuthContextProvider component to wrap the application and provide the authentication context
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
