import { Fragment } from "react"
import "./css/featureGrid.css"
import { Grid, Paper, Typography, Box } from "@mui/material"
import icon1 from "../images/creditcard/icon-1.png"
import icon2 from "../images/creditcard/icon-2.png"
import icon3 from "../images/creditcard/icon-3.png"
import icon4 from "../images/creditcard/icon-4.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import css from "./css/cardSlider.module.css"
import { useMediaQuery } from "react-responsive"
import { useData } from "../DataContext"
import { searchDescByLabel } from "../ults/searchDescByLabel"
import { wrapText } from "../ults/wrapText"

const FeatureCard = ({ icon, title }) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      elevation={2}
      className="feature-card-paper"
    >
      <Box sx={{ mb: 2, height: "100px" }}>
        <img src={icon} alt="icon" className="feature-card-img" />
      </Box>
      <Typography
        variant="span"
        component="span"
        sx={{ mb: 1, fontWeight: "500", fontSize: "1rem" }}
      >
        {title}
      </Typography>
      {/* <Typography variant="body1">{description}</Typography> */}
    </Paper>
  )
}

export default function FeatureGrid_CreditCard() {
  const isBreakpoint = useMediaQuery({ minWidth: 300, maxWidth: 768 })
  const settings = {
    className: `${css.cardSlider_container}`,
    centerPadding: "20px",
    centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const data = useData()?.content?.creditCard

  const List1 = [
    {
      icon: icon1,
      title: wrapText(searchDescByLabel(data, "feature1")),
    },
    {
      icon: icon2,
      title: wrapText(searchDescByLabel(data, "feature2")),
    },
    {
      icon: icon3,
      title: wrapText(searchDescByLabel(data, "feature3")),
    },
    {
      icon: icon4,
      title: wrapText(searchDescByLabel(data, "feature4")),
    },
  ]
  return (
    <div className="featureGrid-papers-container">
      {!isBreakpoint ? (
        <Grid container spacing={2} className="feature-grid">
          {List1.map((item, idx) => (
            <Grid
              item
              xs={2}
              sm={6}
              md={2.5}
              className="feature-grid-item"
              key={idx}
            >
              <FeatureCard icon={item.icon} title={item.title} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Slider {...settings}>
          {List1.map((item, idx) => (
            <div className={css.item}>
              <Grid
                item
                xs={2}
                sm={6}
                md={2.5}
                className="feature-grid-item"
                key={idx}
              >
                <FeatureCard icon={item.icon} title={item.title} />
              </Grid>
            </div>
          ))}
        </Slider>
      )}
    </div>
  )
}
