import "./css/loanProduct.css"
import banner from "../images/applyForm/banner.jpg"
import css from "./css/qa.module.css"
import { HashLink } from "react-router-hash-link"
import { useEffect } from "react"
import Tabs from "../compoients/Tabs"

const QA = () => {
  const list = ["Section 1", "Section 2", "Section 3", "Section 4", "Section 5"]

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className="loanProduct-container marginFixTop">
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${banner}` }}
      >
        <h1 style={{ color: "white", marginTop: "-2rem" }}>常見問題</h1>
      </div>

      <div className={css.container}>
        <Tabs />
      </div>
    </div>
  )
}

export default QA
