import "./css/iconWithText.css"
const IconWithText = ({ icon, title, desc }) => {
  return (
    <div className="feature-item">
      <img src={icon} alt="icon 1" />
      <div className="feature-item-text ps-2">
        <h5 className="color1">{title}​</h5>
        <p>{desc}</p>
      </div>
    </div>
  )
}

export default IconWithText
