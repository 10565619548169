import "./whatsAppBtn.css"
import icon from "../../images/icon/whatsapp_icon.png"
const WhatsAppBtn = ({ phone }) => {
  return (
    <div className="wtsapp">
      <div className="wtsapp-container">
        <a href={`https://wa.me/+852${phone}`}>
          <img className="wtsapp-icon" src={icon} alt="" />
        </a>
      </div>
    </div>
  )
}

export default WhatsAppBtn
