import { Helmet } from "react-helmet"
import { searchDescByLabel } from "../ults/searchDescByLabel"

export function MetaPixel({ data }) {
  const mt = data?.content?.mt
  const MetaPixelScript = searchDescByLabel(mt, "MetaPixelScript")
  const MetaPixelNoScript = searchDescByLabel(mt, "MetaPixelNoScript")

  return (
    <Helmet>
      <script>{MetaPixelScript}</script>
      <noscript>{MetaPixelNoScript}</noscript>
    </Helmet>
  )
}
