const ErrorPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "99px",
      }}
    >
      <p style={{ alignSelf: "center" }}> 404 not found</p>
    </div>
  )
}

export default ErrorPage
