import "./css/loanProduct.css"

import banner from "../images/creditcard/banner.png"
import banner_tablet768 from "../images/privateloan/banner_tablet768.png"
import banner_mobile425 from "../images/creditcard/banner-2-mobile.png"
import stepsImage from "../images/privateloan/application_procedure.jpg"
import { useEffect } from "react"
import ImageDesc from "../compoients/ImageDesc"

import "./css/creditcard.css"
import img1 from "../images/privateloan/point1.png"
import img2 from "../images/privateloan/point2.png"
import img3 from "../images/privateloan/point3.png"
import img4 from "../images/privateloan/point4.png"
import { ApplyBtnBig } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import ApplySteps from "../section/home/ApplySteps"
import FeatureGrid_CreditCard from "../compoients/FeatureGrid_CreditCard"
import { useData } from "../DataContext"
import { wrapText } from "../ults/wrapText"
import { searchDescByLabel } from "../ults/searchDescByLabel"

const CreditCard = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner
    : banner

  const data = useData()?.content?.mortgage
  const bannerText = wrapText(searchDescByLabel(data, "bannerText"))

  return (
    <div className="loanProduct-container mortgage marginFixTop">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="loanProduct-banner-text">
          <h1>大額清卡數</h1>
          <p>{bannerText}</p>
          <button
            className="applyNowBtn_banner button_hover_motion2 "
            onClick={() => {
              navigate("/apply")
            }}
          >
            立即申請
          </button>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="creditcard-container">
          <div>
            <h2>利用適當的私人貸款產品，</h2>
            <h2>就可以輕鬆解決債務問題 !</h2>
          </div>

          <h2 className="mb-5">以私人貸款產品去處理債務，有以下重點</h2>
          <FeatureGrid_CreditCard />
          <div style={{ marginTop: "100px", marginBottom: "80px" }}>
            <ApplySteps />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
