import style from "./calculatorSection.module.css"
import girl_img from "../../images/home/people-2.png"
import Calculator from "../../compoients/Calculator"
const CalculatorSection = () => {
  return (
    <div className={style.container}>
      <div className={style.left_container}>
        <div className="mb-2 color5">
          <h1>貸款計數機</h1>
          <h5>輸入簡單資料，即時計數算您的每月還款額</h5>
        </div>
        <img src={girl_img} alt="girl-with-calculator" />
      </div>
      <div className={style.right_container}>
        <Calculator />
      </div>
    </div>
  )
}

export default CalculatorSection
