import css from "./css/imageDescHoriz.module.css"
import { FormDatatext } from "../hooks/useFormatDatatext"
const ImageHorizontalDesc = ({ image, title, width = 10, desc }) => {
  return (
    <div className={css.horizontalContainer}>
      <div className={css.horizontalImage}>
        <img width={width} src={image} alt="" />
      </div>
      <div className={css.horizontalContentBlock}>
        <div className="title-h3">{FormDatatext(title)}</div>
        <div className="color-p1 desc-style">{FormDatatext(desc)}</div>
      </div>
    </div>
  )
}

export default ImageHorizontalDesc
