export const mainMenu = {
  main: [
    {
      label: "首頁",
      url: "/",
      ordering: 1,
    },
    {
      label: "私人貸款",
      url: "/privateloan",
      ordering: 2,
    },
    {
      label: "大額業主貸款",
      url: "/mortgage",
      ordering: 3,
    },
    {
      label: "卡數一筆清",
      url: "/creditcard",
      ordering: 4,
    },
    {
      label: "關於我們",
      url: "/aboutus",
      ordering: 5,
    },
    {
      label: "熱門文章",
      url: "/blog",
      ordering: 6,
    },
  ],
  loanProduct: [
    {
      label: "私人貸款",
      url: "/privateloan",
      ordering: 1,
    },
    {
      label: "免TU貸款",
      url: "/tu",
      ordering: 2,
    },
    {
      label: "抵押品貸款",
      url: "/collateralLoan",
      ordering: 3,
    },
    {
      label: "業主貸款或物業按揭",
      url: "/mortgage",
      ordering: 4,
    },
    {
      label: "賬戶結餘或清卡數",
      url: "/creditcard",
      ordering: 5,
    },
  ],
  Features: [
    {
      description: "大額",
      ordering: 1,
    },
    {
      description: "火箭速度",
      ordering: 2,
    },
    {
      description: "人性化審核",
      ordering: 3,
    },
    {
      description: "專業服務",
      ordering: 4,
    },
  ],
}
