import { useRef, useCallback, useEffect, useState } from "react"
import css from "./css/home.module.css"
import "./css/home.css"
import { useMediaQuery } from "react-responsive"

import bannerImg from "../images/home/banner.png"

import Calculator from "../compoients/Calculator"

import mute_icon from "../images/home/mute.png"
import sound_icon from "../images/home/sound.png"

import leftArrow from "../images/home/left-arrow.png"
import rightArrow from "../images/home/right-arrow.png"

import peopleImg from "../images/home/part1-people.png"
import people3Img from "../images/home/part3-people.png"
import estimateForm_img from "../images/home/part4-people.png"

import section1Text from "../images/home/homesection1text.png"
import outflame from "../images/home/homeBannerOuterframe.png"
import calculator_bg from "../images/home/Calculatorbg.png"
import { GoBtn } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useFetch from "../hooks/useFetch"
import { ENV } from "../config"
import FeatureSection from "./FeatureSection"
import CalculatorSection from "../section/home/CalculatorSection"
import ApplySteps from "../section/home/ApplySteps"
import Tabs from "../compoients/Tabs"
import { useData } from "../DataContext"

const IconBlock = ({ title, icon, desc }) => {
  return (
    <div className="d-flex flex-row">
      <div className={css.iconBlockImage}>
        <img src={icon} alt={title} />
      </div>
      <div className={["d-flex flex-column", css.iconBlockContent].join(" ")}>
        <h3>{title}</h3>
        <div>{desc}</div>
      </div>
    </div>
  )
}
const NextArrowComponent = ({ onClick }) => {
  return (
    <div className="slider-next-btn" onClick={onClick}>
      <img src={rightArrow} />
    </div>
  )
}

const PrevArrowComponent = ({ onClick }) => {
  return (
    <div className="slider-prev-btn" onClick={onClick}>
      <img src={leftArrow} />
    </div>
  )
}

const Home = () => {
  const data = useData()
  // const scroller = useRef()
  const [reachLeft, setReachLeft] = useState(true)
  const [reachRight, setReachRight] = useState(false)
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })

  const isBreakpoint = useMediaQuery({ minWidth: 501, maxWidth: 960 })
  const navigate = useNavigate()

  // const bannerData = useFetch(ENV + "/api/v1/banner")
  const [topBannerList, setTopBannerList] = useState([])
  const [sliderList, setSliderList] = useState([])

  // useEffect(() => {
  //   if (bannerData && bannerData.data) {
  //     const blogBanners = bannerData.data.filter(
  //       (item) => item.location === "homePromote"
  //     )
  //     setSliderList(blogBanners)

  //     const homeBanners = bannerData.data.filter(
  //       (item) => item.location === "home"
  //     )
  //     setTopBannerList(homeBanners)
  //   }
  // }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const videoRef = useRef(null)

  const settings = {
    className: css.slider_container,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    // nextArrow: <YourNextArrowComponent />,
    // prevArrow: <YourPrevArrowComponent />,
  }

  const homeBannerSettings = {
    className: css.homeBanner_slider_container,
    fade: true,
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const homeBannerRef = useRef(null)
  const sliderRef = useRef(null)
  const goToNext = () => {
    // Assuming you have access to slider's ref
    sliderRef.current.slickNext()
  }

  const goToPrevious = () => {
    // Assuming you have access to slider's ref
    sliderRef.current.slickPrev()
  }

  const [pageYOffset, setPageYOffset] = useState(0)

  const newSectionSlider_Ref = useRef(null)

  // desktop animations
  const home_section2_ref = useRef(null)
  const home_section3_item_ref = useRef(null)
  const home_section4_ref = useRef(null)
  const home_section5_ref = useRef(null)

  //mobile animations
  const section1_container_mobile_Ref = useRef(null)
  const section2_upperTitle_mobile_Ref = useRef(null)
  const peopleImg_mobile_Ref = useRef(null)
  const estimateForm_img_Ref = useRef(null)

  useEffect(() => {
    function handleScroll() {
      setPageYOffset(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isDesktop) {
      if (pageYOffset >= 700) {
        // home_section2_ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 1250) {
        home_section3_item_ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 2100) {
        home_section4_ref.current.classList.add("fade-right-element")
      }
      if (pageYOffset >= 2700) {
        home_section5_ref.current.classList.add("fade-left-element")
      }

      // if (pageYOffset >= 2100) {
      //   newSection3_inner_container_left_Ref.current.classList.add(
      //     "fade-right-element"
      //   )
      //   car_Ref.current.classList.add("fade-left-element")
      // }
      // if (pageYOffset >= 2800) {
      //   estimateForm_Ref.current.classList.add("fade-up-element")
      // }

      // if (pageYOffset >= 3200) {
      //   sectionLast_Ref.current.classList.add("fade-in-element")
      // }
    }
    if (isMobile || isMobile_toogle) {
      if (pageYOffset >= 400) {
        // home_section2_ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 1100) {
        home_section3_item_ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 1900) {
        home_section4_ref.current.classList.add("fade-right-element")
      }
      if (pageYOffset >= 2700) {
        home_section5_ref.current.classList.add("fade-left-element")
      }

      // section1_container_mobile_Ref.current.classList.add("fade-up-element")
      // if (pageYOffset >= 500) {
      //   section2_upperTitle_mobile_Ref.current.classList.add("fade-up-element")
      //   peopleImg_mobile_Ref.current.classList.add("fade-up-element")
      // }
      // if (pageYOffset >= 1100) {
      //   newSectionSlider_Ref.current.classList.add("fade-up-element")
      // }
      // if (pageYOffset >= 1900) {
      //   newSection3_inner_container_left_Ref.current.classList.add(
      //     "fade-up-element"
      //   )
      //   car_Ref.current.classList.add("fade-up-element")
      // }

      // if (pageYOffset >= 2900) {
      //   estimateForm_img_Ref.current.classList.add("fade-in-element")
      //   estimateForm_Ref.current.classList.add("fade-in-element")
      // }
      // if (pageYOffset >= 3800) {
      //   sectionLast_Ref.current.classList.add("fade-in-element")
      // }
    }
  }, [pageYOffset])

  const [muteImg, setMuteImg] = useState(mute_icon)

  const [deviceSystem, setDeviceSystem] = useState("")

  useEffect(() => {
    const userAgent = navigator.userAgent
    let system = ""

    if (userAgent.match(/Windows/i)) {
      system = "Windows"
    } else if (userAgent.match(/Mac/i)) {
      system = "macOS"
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      system = "iOS"
    } else if (userAgent.match(/Android/i)) {
      system = "Android"
    }
    setDeviceSystem(system)
  }, [])

  function VideoPlayer() {
    if (deviceSystem === "iOS" && deviceSystem === "macOS") {
      return (
        <video
          id="ios_vd"
          ref={videoRef}
          muted
          playsinline
          autoplay
          loop
          width="100%"
          controls={false}
          style={{ border: "none", outline: "none", marginBottom: "-5px" }}
        >
          <source src={"/FFL_final_cut_render1.mp4"} type="video/mp4" />
        </video>
      )
    } else {
      return (
        <video
          id="vd"
          muted
          playsInline
          autoPlay
          loop
          width="100%"
          controls={false}
          style={{ border: "none", outline: "none", marginBottom: "-5px" }}
        >
          <source src={"/FFL_final_cut_render1.mp4"} type="video/mp4" />
        </video>
      )
    }
  }

  return (
    <div className="home">
      <div className="home_section1">
        <div className={css.homeBanner}></div>
        <div className={`${css.container}`}>
          {/* {VideoPlayer()} */}
          {/* <video
            id="vd"
            ref={videoRef}
            muted
            playsInline
            autoPlay={true}
            loop={true}
            controls={true}
            style={{ border: "none", outline: "none", marginBottom: "-5px" }}
          >
            <source src={"/FFL_final_cut_render1.mp4"} type="video/mp4" />
          </video> */}
          {/* <div
            onClick={() => {
              const video = document.getElementById("vd")
              video.muted = !video.muted
              if (video.muted) {
                setMuteImg(mute_icon)
              } else {
                setMuteImg(sound_icon)
              }
            }}
            style={{ backgroundImage: `url(${muteImg})` }}
            className="home_video_mute_btn"
          /> */}
        </div>
      </div>

      {/* section 2 */}
      <div className="home_section2" ref={home_section2_ref}>
        <FeatureSection />
      </div>

      <div className="home_section3">
        <div className="home_section3_item" ref={home_section3_item_ref}>
          <CalculatorSection />
        </div>
      </div>
      <div className="home_section4" ref={home_section4_ref}>
        <ApplySteps />
      </div>

      <div className="home_section5" ref={home_section5_ref}>
        <h2>常見問題</h2>
        <Tabs />
      </div>
    </div>
  )
}

export default Home
