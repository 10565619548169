import "./facebookBtn.css"
import fbBtn from "../../images/icon/facebook.png"
const FbBtn = ({ src }) => {
  return (
    <div className="fbBtn">
      <a href={src}>
        <img src={fbBtn} alt="" />
      </a>
    </div>
  )
}

export default FbBtn
