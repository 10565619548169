import { db } from "../firebase"
import {
  updateDoc,
  arrayUnion,
  deleteDoc,
  setDoc,
  addDoc,
  collection,
  doc,
  getDoc,
  query,
  getDocs,
  where,
  orderBy,
  serverTimestamp,
} from "firebase/firestore"
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  listAll,
  deleteObject,
} from "firebase/storage"
import moment from "moment"
import { approvalStatusOptions } from "./keyvalue/approvalStatusOptions"

export const storage = getStorage()

export async function ErrorMsgLogger(id, msg) {
  const docRef = doc(db, "ErrorLog", id)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    await updateDoc(docRef, {
      files: arrayUnion({
        message: msg,
        timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
      }),
    })
    return (msg = "success")
  } else {
    await setDoc(doc(db, "ErrorLog", id), {
      idCard: id,
      files: [
        {
          message: msg,
          timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
        },
      ],
    })
  }
}

export async function uploadSignedDoc(file, id, filename) {
  let msg = ""
  let downloadURL = ""
  if (!file) return
  const storageRef = ref(storage, `/SignedDoc/${filename}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => console.log(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        downloadURL = url
        console.log(downloadURL)
        createUploadSignedDocRec(id, filename, downloadURL)
        deleteUserDoc(id, filename)
      })
    }
  )

  return (msg = "success")
}

export async function createUploadSignedDocRec(id, fileName, downloadURL) {
  try {
    const docRef = doc(db, "SignedDoc", id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        files: arrayUnion({
          fileName: fileName,
          downloadURL: downloadURL,
          isSigned: true,
          timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
        }),
      })
    } else {
      await setDoc(doc(db, "SignedDoc", id), {
        idCard: id,
        files: [
          {
            fileName: fileName,
            downloadURL: downloadURL,
            isSigned: true,
            timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
          },
        ],
      })
    }
  } catch (e) {
    console.error("Error adding document: ", e)
  }
}

export async function uploadFile(file, id) {
  let msg = ""
  let downloadURL = ""
  console.log("file:", file)
  // const fileName = id + "_" + file.name
  const fileName = id + "_contract.pdf"
  if (!file) return

  //delete existing file
  await deleteUserDoc(id, fileName)

  //check if doc exist
  const userDocs = []
  const q = query(collection(db, "DocToSign"), where("idCard", "==", id))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data())
    const { files } = doc.data()
    userDocs.push({
      files: files,
    })
  })

  const storageRef = ref(storage, `/DocToSign/${id}/${fileName}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => console.log(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        downloadURL = url
        console.log(downloadURL)
        createUploadDocRec(id, fileName, downloadURL)
      })
    }
  )

  return (msg = "success")
}

export async function uploadSupportingFile(file, id) {
  let msg = ""
  let downloadURL = ""
  console.log("file:", file?.name)
  // const fileName = id + "_" + file.name
  const fileName = file?.name
  if (!file) return

  //check if doc exist
  // const userDocs = []
  // const q = query(collection(db, "SupportingFile"), where("idCard", "==", id))
  // const querySnapshot = await getDocs(q)
  // querySnapshot.forEach((doc) => {
  //   const { files } = doc.data()
  //   userDocs.push({
  //     files: files,
  //   })
  // })

  const storageRef = ref(storage, `/SupportingFile/${id}/${fileName}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
    },
    (err) => console.log(err),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        downloadURL = url
        console.log(downloadURL)
        createUploadDocRec(id, fileName, downloadURL)
      })
    }
  )

  return (msg = "success")
}

export async function createUploadDocRec(id, fileName, downloadURL) {
  try {
    const docRef = doc(db, "DocToSign", id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        files: arrayUnion({
          fileName: fileName,
          downloadURL: downloadURL,
          isSigned: false,
          timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
        }),
      })
    } else {
      await setDoc(doc(db, "DocToSign", id), {
        idCard: id,
        files: [
          {
            fileName: fileName,
            downloadURL: downloadURL,
            isSigned: false,
            timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
          },
        ],
      })
    }
  } catch (e) {
    console.error("Error adding document: ", e)
  }
}

export async function getUserAllDocs(id) {
  let error = ""
  const userDocs = []
  const q = query(collection(db, "DocToSign"), where("idCard", "==", id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    const { files } = doc.data()
    const docId = doc.id
    userDocs.push({
      files: files,
      docId: docId,
    })
  })
  return userDocs
}

export async function getUserSignedDocs(id) {
  let error = ""
  const userDocs = []
  const q = query(collection(db, "SignedDoc"), where("idCard", "==", id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    const { files } = doc.data()
    const docId = doc.id
    userDocs.push({
      files: files,
      docId: docId,
    })
  })
  return userDocs
}

export async function getUserUnsignDocs(id) {
  let error = ""
  const userDocs = []
  const q = query(collection(db, "DocToSign"), where("idCard", "==", id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    const { files } = doc.data()
    const docId = doc.id
    userDocs.push({
      files: files,
      docId: docId,
    })
  })
  return userDocs
}

export async function createUser(user) {
  let error = ""
  let success = ""
  const { idCard, password, displayName, loanNo } = user

  const docRef = doc(db, "users", idCard)
  const docSnap = await getDoc(docRef)
  try {
    if (docSnap.exists()) {
      error = "不能新增已註冊身份證號碼"
    } else {
      const docRef = await setDoc(doc(db, "users", idCard), {
        idCard: idCard,
        password: password,
        displayName: displayName,
        phoneNumb: password,
        loanNo: loanNo,
        approvalStatus: "pending_sign",
        timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
        createdAt: serverTimestamp(),
      })

      success = "註冊成功!"
    }
  } catch (e) {
    console.error("Error adding document: ", e)
  }
  return { error, success }
}

//User
export async function getUserDataFromPhone(user) {
  let error = ""
  let result = false
  let userDetail = {}
  const { phoneNumb } = user

  try {
    const q = query(
      collection(db, "users"),
      where("phoneNumb", "==", phoneNumb)
    )

    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) {
      error = "請輸入正確身份証號碼及密碼"
    } else {
      querySnapshot.forEach((doc) => {
        const { displayName, timestamp, password, approvalStatus } = doc.data()

        const docId = doc.id
        userDetail = {
          displayName: displayName,
          docId: docId,
          approvalStatus: approvalStatus,
          timestamp: timestamp,
          password: password,
        }
        result = true
      })
    }
  } catch (e) {
    console.error(e.message)
  }
  return { error, result, userDetail }
}
export async function getUserData(user) {
  let error = ""
  let result = false
  let userDetail = {}
  const { idCard, password } = user

  try {
    const q = query(collection(db, "users"), where("idCard", "==", idCard))

    const querySnapshot = await getDocs(q)

    console.log(querySnapshot)

    if (querySnapshot.empty) {
      error = "請輸入正確身份証號碼及密碼"
    } else {
      querySnapshot.forEach((doc) => {
        const { displayName, timestamp, password, approvalStatus } = doc.data()

        const docId = doc.id
        userDetail = {
          displayName: displayName,
          docId: docId,
          approvalStatus: approvalStatus,
          timestamp: timestamp,
          password: password,
        }
        result = true
      })
    }
  } catch (e) {
    console.error(e.message)
  }
  return { error, result, userDetail }
}

export async function getUserList() {
  let error = ""
  const userList = []
  const q = query(
    collection(db, "users"),
    // where("idCard", "!=", "superadmin"),
    orderBy("createdAt", "desc")
  )
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    const { displayName, idCard, loanNo, approvalStatus, timestamp } =
      doc.data()
    const docId = doc.id
    if (idCard !== "superadmin")
      userList.push({
        idCard: idCard,
        displayName: displayName,
        docId: docId,
        loanNo: loanNo,
        approvalStatus: approvalStatus,
        timestamp: timestamp,
      })
  })
  return userList
}

export async function deleteUser(id) {
  let error = ""
  let msg = ""
  await deleteDoc(doc(db, "users", id))

  return (msg = "deleted")
}

export function deleteFromStorage(filePath) {
  const deleteRef = ref(storage, filePath)
  // Delete the file
  deleteObject(deleteRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {})
}

export async function deleteUserDoc(id, fileName) {
  let error = ""
  let userDoctoSignList = []
  const q = query(collection(db, "DocToSign"), where("idCard", "==", id))
  const querySnapshot = await getDocs(q)
  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      const { files } = doc.data()
      files.forEach((file) => {
        //construct updated doc list
        if (file.fileName !== fileName)
          userDoctoSignList.push({
            fileName: file.fileName,
            downloadURL: file.downloadURL,
            isSigned: false,
            timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
          })
      })
    })
    //update doc list
    await updateDocToSign(id, userDoctoSignList)
  }
}

export async function getDocToSign(id) {
  let error = ""
  const userDocs = []
  const q = query(collection(db, "DocToSign"), where("idCard", "==", id))

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    const { files } = doc.data()
    const docId = doc.id
    userDocs.push({
      files: files,
      docId: docId,
    })
  })
  return userDocs
}
export async function updateUserPassword(id, newPassword) {
  let error = ""
  let result = ""

  const docRef = doc(db, "users", id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const { displayName, loanNo, approvalStatus, timestamp } = docSnap.data()
    setTimeout(() => {
      updateUserData(displayName, loanNo, approvalStatus, timestamp)
    }, 1000)
    result = "success"
  } else {
    error = "fail"
  }

  async function updateUserData(
    displayName,
    loanNo,
    approvalStatus,
    timestamp
  ) {
    const userRef = doc(db, "users", id)
    await setDoc(userRef, {
      idCard: id,
      password: newPassword,
      displayName: displayName,
      loanNo: loanNo,
      timestamp: timestamp,
      approvalStatus: approvalStatus,
      createdAt: serverTimestamp(),
    })
  }

  return { error, result }
}

export async function updateDocToSign(id, files) {
  const docRef = doc(db, "DocToSign", id)
  await setDoc(docRef, {
    files: files,
    idCard: id,
  })
}

export async function activateUserAccount(id) {
  let error = ""
  let result = ""

  const docRef = doc(db, "users", id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const { displayName, password, loanNo } = docSnap.data()
    setTimeout(() => {
      updateUserData(displayName, password, loanNo)
    }, 1000)
    result = "success"
  } else {
    error = "fail"
  }

  async function updateUserData(displayName, password, loanNo) {
    const userRef = doc(db, "users", id)
    await setDoc(userRef, {
      idCard: id,
      password: password,
      displayName: displayName,
      loanNo: loanNo,
      timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
      createdAt: serverTimestamp(),
    })
  }
  return { error, result }
}

export async function ChangeApprovalStatus(id, approvalStatus) {
  let error = ""
  let result = ""

  const docRef = doc(db, "users", id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const { displayName, password, loanNo } = docSnap.data()
    setTimeout(() => {
      updateUserData(displayName, password, loanNo, approvalStatus)
    }, 1000)
    result = "success"
  } else {
    error = "fail"
  }

  async function updateUserData(displayName, password, loanNo, approvalStatus) {
    const userRef = doc(db, "users", id)
    await setDoc(userRef, {
      idCard: id,
      password: password,
      displayName: displayName,
      approvalStatus: approvalStatus,
      loanNo: loanNo,
      timestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
      createdAt: serverTimestamp(),
    })
  }
  return { error, result }
}
