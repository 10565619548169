export const searchDescByLabel = (data, label) => {
  if (data) {
  

  let result = "";

  const search = (items) => {
    items.forEach(item => {
      if (item?.label === label) {
        result = item?.description
      }
    });
  };

  if (Array.isArray(data)) {
    search(data);
  } else {
    Object.values(data).forEach(items => {
      if (Array.isArray(items)) {
        search(items);
      }
    });
  }

  return result;
}
};